var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"v173.3.2"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import config from "./config";

const SENTRY_DSN: string = config.sentry.DSN;
const environment: string = config.sentry.environment;

Sentry.init({
  enabled: environment === "production" && process.env.NODE_ENV !== "development",
  dsn: SENTRY_DSN || "https://examplePublicKey@o0.ingest.sentry.io/0",
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
// Replay may only be enabled for the client-side
//   integrations: [new Sentry.Replay()],
  integrations: [
    new Sentry.Breadcrumbs(),
    new Sentry.GlobalHandlers(),
    new Sentry.LinkedErrors(),
    new Sentry.Dedupe()
  ],
  transport: Sentry.makeFetchTransport,
  stackParser: Sentry.defaultStackParser,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
  environment: environment
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
