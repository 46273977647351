export const CLOSE_CURRENT_POPUP = 'CLOSE_CURRENT_POPUP';
export const CLOSE_POPUP = 'CLOSE_POPUP';
export const CLOSE_ALL_POPUP = 'CLOSE_ALL_POPUP';
export const SET_DEFAULT_VIDEO = 'SET_DEFAULT_VIDEO';
export const SET_SCREEN = 'SET_SCREEN';

// Open Popup Actions
export const OPEN_SHARE_CONFIRM = 'OPEN_SHARE_CONFIRM';
export const OPEN_NEW_POPUP = 'OPEN_NEW_POPUP';
export const OPEN_DEMO_POPUP = 'OPEN_DEMO_POPUP';
export const OPEN_VIDEO_POPUP = 'OPEN_VIDEO_POPUP';
export const OPEN_VIDEO_POPUP_V2 = 'OPEN_VIDEO_POPUP_V2';
export const OPEN_SECONDARY_LANGUAGE_CONFIRM_POPUP =
  'OPEN_SECONDARY_LANGUAGE_CONFIRM_POPUP';
export const OPEN_DOWNGRADE_PLAN_POPUP = 'OPEN_DOWNGRADE_PLAN_POPUP';
export const OPEN_VIDEO_POPUP_V3 = 'OPEN_VIDEO_POPUP_V3';
export const OPEN_PUBLIC_VIDEO_POPUP = 'OPEN_PUBLIC_VIDEO_POPUP';
export const OPEN_PUBLIC_VIDEO_SIMPLE_FORM_POPUP =
  'OPEN_PUBLIC_VIDEO_SIMPLE_FORM_POPUP';
export const OPEN_SCHEDULE_POPUP = 'OPEN_SCHEDULE_POPUP';
export const OPEN_CALENDAR_SCHEDULE_POPUP = 'OPEN_CALENDAR_SCHEDULE_POPUP';
export const OPEN_DOWNLOAD_VIDEO_POPUP = 'OPEN_DOWNLOAD_VIDEO_POPUP';
export const OPEN_UPGRADE_POPUP = 'OPEN_UPGRADE_POPUP';
export const OPEN_FREE_PLAN_POPUP = 'OPEN_FREE_PLAN_POPUP';
export const OPEN_ALL_SOCIALS_POPUP = 'OPEN_ALL_SOCIALS_POPUP';
export const OPEN_CONNECT_SOCIALS_POPUP = 'OPEN_CONNECT_SOCIALS_POPUP';
export const OPEN_REGISTER_POPUP = 'OPEN_REGISTER_POPUP';
export const OPEN_SIMPLE_VIDEO_PLAYER_POPUP = 'OPEN_SIMPLE_VIDEO_PLAYER_POPUP';
export const OPEN_NEWS_IMAGE_DOWNLOAD_SUCCESS =
  'OPEN_NEWS_IMAGE_DOWNLOAD_SUCCESS';
export const OPEN_SHARE_BRANDED_NEWS = 'OPEN_SHARE_BRANDED_NEWS';
export const OPEN_SHARE_BRANDED_NEWS_LEGACY = 'OPEN_SHARE_BRANDED_NEWS_LEGACY';
export const OPEN_DOWNLOAD_NEWS_IMAGE = 'OPEN_DOWNLOAD_NEWS_IMAGE';
export const OPEN_NEWS_STYLE_SETTINGS = 'OPEN_NEWS_STYLE_SETTINGS';
export const OPEN_ONBOARDING_SHARE_POPUP = 'OPEN_ONBOARDING_SHARE_POPUP';
export const OPEN_FOLLOW_UP_TEMPLATES_POPUP = 'OPEN_FOLLOW_UP_TEMPLATES_POPUP';
export const OPEN_FOLLOW_UP_MESSAGES_POPUP = 'OPEN_FOLLOW_UP_MESSAGES_POPUP';
export const OPEN_EDIT_VIDEO_BRANDING_POPUP = 'OPEN_EDIT_VIDEO_BRANDING_POPUP';
export const OPEN_LEAD_GENERATION_POPUP = 'OPEN_LEAD_GENERATION_POPUP';
export const OPEN_LANDING_SHARED_POPUP = 'OPEN_LANDING_SHARED_POPUP';
export const OPEN_EDIT_INFO_POPUP = 'OPEN_EDIT_INFO_POPUP';
export const OPEN_YOUTUBE_POPUP = 'OPEN_YOUTUBE_POPUP';
export const OPEN_IFRAME_POPUP = 'OPEN_IFRAME_POPUP';
export const OPEN_ACCEPT_FACEBOOK_TERMS_POPUP =
  'OPEN_ACCEPT_FACEBOOK_TERMS_POPUP';
export const OPEN_ADS_CUSTOMIZE_LOCATION_POPUP =
  'OPEN_ADS_CUSTOMIZE_LOCATION_POPUP';
export const OPEN_ADD_SCHEDULED_POST_BROKERAGE_POPUP =
  'OPEN_ADD_SCHEDULED_POST_BROKERAGE_POPUP';
export const OPEN_BROKERAGE_AGENT_IMAGE_POST_POPUP =
  'OPEN_BROKERAGE_AGENT_IMAGE_POST_POPUP';
export const OPEN_FACEBOOK_BUSINESS_CONNECTION_CONFIRM =
  'OPEN_FACEBOOK_BUSINESS_CONNECTION_CONFIRM';
export const OPEN_NEW_FEATURE_NURTURE_LEADS_POPUP =
  'OPEN_NEW_FEATURE_NURTURE_LEADS_POPUP';
export const OPEN_SEND_SETUP_EMAIL_POPUP = 'OPEN_SEND_SETUP_EMAIL_POPUP';
export const OPEN_SEND_SETUP_REMINDER_EMAIL_POPUP =
  'OPEN_SEND_SETUP_REMINDER_EMAIL_POPUP';
export const OPEN_SCHEDULE_POST_BROKERAGE_AGENT_POPUP =
  'OPEN_SCHEDULE_POST_BROKERAGE_AGENT_POPUP';
export const OPEN_CONFIRM_POPUP = 'OPEN_CONFIRM_POPUP';
export const OPEN_UPGRADE_CLOSE_POPUP = 'OPEN_UPGRADE_CLOSE_POPUP';
export const OPEN_SET_LOCATION_POPUP = 'OPEN_SET_LOCATION_POPUP';
export const OPEN_ASK_VIDEO_POPUP = 'OPEN_ASK_VIDEO_POPUP';
export const OPEN_CREATE_NEW_JOURNEY_POPUP = 'OPEN_CREATE_NEW_JOURNEY_POPUP';
export const OPEN_EDIT_CRM_ACTION_TEMPLATE_POPUP =
  'OPEN_EDIT_CRM_ACTION_TEMPLATE_POPUP';
export const OPEN_CALENDAR_SETTINGS_POPUP = 'OPEN_CALENDAR_SETTINGS_POPUP';
export const OPEN_UPGRADE_SUCCESS_POPUP = 'OPEN_UPGRADE_SUCCESS_POPUP';
export const OPEN_MANAGE_USER_GROUP_POPUP = 'OPEN_MANAGE_USER_GROUP_POPUP';
export const OPEN_COMPLETE_YOUR_PROFILE_POPUP =
  'OPEN_COMPLETE_YOUR_PROFILE_POPUP';
export const OPEN_AFTER_PAYMENT_POPUP = 'OPEN_AFTER_PAYMENT_POPUP';
export const OPEN_PAYMENT_COUPON_POPUP = 'OPEN_PAYMENT_COUPON_POPUP';
export const OPEN_VIDEO_NEWS_POPUP = 'OPEN_VIDEO_NEWS_POPUP';
export const OPEN_DOWNLOAD_USER_VIDEO_POPUP = 'OPEN_DOWNLOAD_USER_VIDEO_POPUP';
export const OPEN_CALENDAR_PREVIEW_POPUP = 'OPEN_CALENDAR_PREVIEW_POPUP';
export const OPEN_EDIT_LISTING_POPUP = 'OPEN_EDIT_LISTING_POPUP';
export const OPEN_ADD_LISTING_POPUP = 'OPEN_ADD_LISTING_POPUP';
export const OPEN_IMPORT_FROM_ZILLOW_CONFIRM_POPUP =
  'OPEN_IMPORT_FROM_ZILLOW_CONFIRM_POPUP';
export const OPEN_ADD_LISTING_CONFIRM_POPUP = 'OPEN_ADD_LISTING_CONFIRM_POPUP';
export const OPEN_HYPERLOCAL_VIDEO_POPUP = 'OPEN_HYPERLOCAL_VIDEO_POPUP';
export const OPEN_VIDEO_TESTIMONIAL_POPUP = 'OPEN_VIDEO_TESTIMONIAL_POPUP';
export const OPEN_SHARE_LISTING_POPUP = 'OPEN_SHARE_LISTING_POPUP';
export const UPDATE_POPUP_DATA = 'UPDATE_POPUP_DATA';
export const OPEN_SOCIALS_SETTINGS_POPUP = 'OPEN_SOCIALS_SETTINGS_POPUP';
export const OPEN_FAKE_EMAIL_POPUP = 'OPEN_FAKE_EMAIL_POPUP';
export const OPEN_VIDEO_CREATOR_POPUP = 'OPEN_VIDEO_CREATOR_POPUP';
export const OPEN_VIDEO_RECORDER_POPUP = 'OPEN_VIDEO_RECORDER_POPUP';
export const OPEN_INSTAGRAM_RECONNECT_POPUP = 'OPEN_INSTAGRAM_RECONNECT_POPUP';
export const OPEN_SOCIAL_CONNECT_REMINDER_POPUP =
  'OPEN_SOCIAL_CONNECT_REMINDER_POPUP';
export const OPEN_CONTENT_FACTORY_EDIT_TEXT = 'OPEN_CONTENT_FACTORY_EDIT_TEXT';
export const OPEN_PHONE_VERIFICATION_POPUP = 'OPEN_PHONE_VERIFICATION_POPUP';
export const OPEN_PREMIUM_BRANDING_POPUP = 'OPEN_PREMIUM_BRANDING_POPUP';
export const OPEN_CONTENT_FACTORY_BRANDING_POPUP =
  'OPEN_CONTENT_FACTORY_BRANDING_POPUP';
export const OPEN_CONTENT_FACTORY_GENERATE_VIDEO_POPUP =
  'OPEN_CONTENT_FACTORY_GENERATE_VIDEO_POPUP';
export const OPEN_IMPORT_NEWS_POPUP = 'OPEN_IMPORT_NEWS_POPUP';
export const OPEN_UPLOAD_HEADSHOT_POPUP = 'OPEN_UPLOAD_HEADSHOT_POPUP';
export const OPEN_SOCIAL_AUDIT_POPUP = 'OPEN_SOCIAL_AUDIT_POPUP';
export const OPEN_SOCIAL_AUDIT_READY_POPUP = 'OPEN_SOCIAL_AUDIT_READY_POPUP';
export const OPEN_DISCOUNT_POPUP = 'OPEN_DISCOUNT_POPUP';
export const OPEN_UPGRADE_ALL_PLANS_POPUP = 'OPEN_UPGRADE_ALL_PLANS_POPUP';
export const OPEN_REWARD_POPUP = 'OPEN_REWARD_POPUP';
export const OPEN_SIX_MONTHS_OFF_POPUP = 'OPEN_SIX_MONTHS_OFF_POPUP';
export const OPEN_PAUSE_SUBSCRIPTION_POPUP = 'OPEN_PAUSE_SUBSCRIPTION_POPUP';
export const OPEN_RESUME_SUBSCRIPTION_POPUP = 'OPEN_RESUME_SUBSCRIPTION_POPUP';
export const OPEN_CONFIRM_DOWNGRADE_POPUP = 'OPEN_CONFIRM_DOWNGRADE_POPUP';
export const OPEN_PRICING_SUGGESTION_POPUP = 'OPEN_PRICING_SUGGESTION_POPUP';
