import { IPlanDetails, IPlans, IUser } from '@/types/user';
import { UpgradePlans } from '@/types/subscription';

export function isPremiumPlan(subscription: IUser['subscription']) {
  return subscription === UpgradePlans.premium;
}

export function isPlatinumPlan(subscription: IUser['subscription']) {
  return subscription === UpgradePlans.platinum;
}

export function isBasicPlan(subscription: IUser['subscription']) {
  return subscription === UpgradePlans.basic;
}

export function getPlan(
  plans: IPlans,
  planInfo: string
): Partial<IPlanDetails> {
  if (!plans) return {};

  return plans[planInfo];
}
