import { IListing } from '@/types/listing';
import { INewsArticle } from '@/types/news';
import { IVideo } from '@/types/video';

export enum Steps {
  UPDATE_PERSONAL_INFO,
  EDIT_LISTING_INFO,
  SELECT_TEMPLATE_COLOR,
  TRAIN_AI_VOICE,
  START_TRAIN_AI_VOICE,
  RECORD_VOICE,
  START_RECORD_VOICE,
  RECORD_PERSONAL_INTRO,
  START_RECORD_PERSONAL_INTRO,
  SHARE_SAVE,
}

export interface VideoCreatorPopupState {
  currentStep: Steps;
}

export enum VideoCreatorContentType {
  VIDEO = 'video',
  NEWS = 'news',
  LISTING = 'listing',
}

interface VideoContent {
  type: VideoCreatorContentType.VIDEO;
  video: IVideo;
  news?: INewsArticle;
  listing?: IListing;
  initialStep?: Steps;
}

interface NewsContent {
  type: VideoCreatorContentType.NEWS;
  news: INewsArticle;
  video?: IVideo;
  listing?: IListing;
  initialStep?: Steps;
}

interface ListingContent {
  type: VideoCreatorContentType.LISTING;
  listing: IListing;
  news?: INewsArticle;
  video?: IVideo;
  initialStep?: Steps;
}

export type VideoCreatorPopupType = (
  | VideoContent
  | NewsContent
  | ListingContent
) & {
  closeOnBack?: boolean;
};
