import { UpgradePlans } from '@/types/subscription';
import { getUpgradePlanName } from '@/helpers/user-feature';

export const SubscriptionPlans = {
  basic: {
    sixMonths: 'basic_6months',
    monthly: 'basic_monthly',
    yearly: 'basic_yearly',
  },
  premium: {
    sixMonths: 'premium_6months_b',
    monthly: 'premium_monthly_b',
    yearly: 'premium_yearly_c',
  },
  platinum: {
    sixMonths: 'platinum_6months_d',
    monthly: 'platinum_monthly_d',
    yearly: 'platinum_yearly_d',
  },
  diamond: {
    sixMonths: 'diamond_6months_b',
    yearly: 'diamon_yearly_b',
  },
  newsletter: {
    monthly: 'newsletter_monthly',
    yearly: 'newsletter_yearly',
  },
};

type ConfigTypes = {
  [key in Exclude<UpgradePlans, UpgradePlans.none>]: {
    key: UpgradePlans;
    name: Capitalize<UpgradePlans>;
  };
};

export const SubscriptionPlanConfigs: ConfigTypes = {
  basic: {
    key: UpgradePlans.basic,
    name: getUpgradePlanName(UpgradePlans.basic),
  },
  premium: {
    key: UpgradePlans.premium,
    name: getUpgradePlanName(UpgradePlans.premium),
  },
  platinum: {
    key: UpgradePlans.platinum,
    name: getUpgradePlanName(UpgradePlans.platinum),
  },
  diamond: {
    key: UpgradePlans.diamond,
    name: getUpgradePlanName(UpgradePlans.diamond),
  },
  newsletter: {
    key: UpgradePlans.newsletter,
    name: getUpgradePlanName(UpgradePlans.newsletter),
  },
};
